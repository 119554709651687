import { FormikProps } from 'formik';
import React from 'react';
import { Autocomplete } from '../Autocomplete/Autocomplete';
import { FormField } from '../FormField/FormField';

type Props<T, F> = {
  formik: FormikProps<F>;
  field: keyof F;
  placeholder?: string;
  fetch: (query: string) => Promise<T[]>;
  getLabel: (v: T) => string;
  label?: string;
};

export function FormikAutocomplete<T extends { id: number | string }, K>(props: Props<T, K>) {
  const { formik, field, placeholder, fetch, getLabel, label } = props;

  const handleSelect = (v: T | null) => {
    formik.setFieldValue(String(field), v);
    formik.setFieldTouched(String(field), true, false);
  };

  return (
    <FormField
      label={label}
      error={
        formik.touched[field] && formik.errors[field] ? String(formik.errors[field]) : undefined
      }
    >
      <Autocomplete<T>
        fetch={fetch}
        name={String(field)}
        value={formik.values[field] as unknown as T}
        getLabel={getLabel}
        onSelect={handleSelect}
        onBlur={formik.handleBlur}
        placeholder={placeholder}
      />
    </FormField>
  );
}
