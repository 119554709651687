import { useQuery } from '@apollo/client';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { ORDERS_QUERY } from '../../api/public/cabinet/orders';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import OrdersListTable from './OrdersListTable';
import OrdersListMobile from './OrdersListMobile';
import OrdersMobileSkeleton from './OrdersMobileSkeleton/OrdersMobileSkeleton';
import OrdersSkeleton from './OrdersSkeleton/OrdersSkeleton';

export const OrdersList = () => {
  const { data, error, loading } = useQuery(ORDERS_QUERY, {
    variables: {
      limit: 100,
      offset: 0,
    },
    fetchPolicy: 'no-cache',
  });

  const orders = data?.orders.data;

  const skeleton = isMobile ? <OrdersMobileSkeleton /> : <OrdersSkeleton />;
  const layer = isMobile ? <OrdersListMobile orders={orders!} /> : <OrdersListTable orders={orders!} />;

  return <>{error ? <ErrorAlert error={error} /> : loading ? skeleton : layer}</>;
};

export default OrdersList;
