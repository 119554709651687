import Image from 'next/image';
import Link from 'next/link';
import React, { ReactNode } from 'react';
import styles from './CabinetContentLayout.module.scss';

type Props = {
  backLink?: string;
  header?: ReactNode;
  actions?: React.ReactNode | React.ReactNode[];
  children: React.ReactNode;
};

export function CabinetContentLayout({ backLink, header, actions, children }: Props) {
  return (
    <>
      <div className={styles.header}>
        {(backLink || header) && (
          <div className={styles.backLink}>
            {backLink && (
              <Link passHref href={backLink} legacyBehavior>
                <div className={styles.backButton}>
                  <Image src="/svg/arrow-right.svg" width={22} height={22} alt="" />
                </div>
              </Link>
            )}
            {header && <span>{header}</span>}
          </div>
        )}
        {actions && <div>{actions}</div>}
      </div>
      <div className={styles.container}>{children}</div>
    </>
  );
}

export default CabinetContentLayout;
