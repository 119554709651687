import { gql } from "../../__generated__";

export const SUGGESTION_SEND = gql(`
  mutation SendSuggestion($suggestion: SuggestionPayload!) {
    sendSuggestion(suggestion: $suggestion)
  }
`);


export const CONTACT_MANAGER = gql(`
  mutation ContactManager($suggestion: SuggestionPayload!) {
    contactManager(suggestion: $suggestion)
  }
`);