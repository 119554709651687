import { useEffect, useState } from 'react';

export function useDebouncedState<T>(value: T, delay: number = 700): T {
  const [debounced, setDebounced] = useState<T>(value);

  useEffect(() => {
    const handle = setTimeout(() => {
      setDebounced(value);
    }, delay);
    return (): void => clearTimeout(handle);
  }, [value, delay]);

  return debounced;
}

export default useDebouncedState;
