import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import styles from './SnackbarItem.module.scss';

const doneIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M9 16.2L4.8 12l-1.4 1.4L9
      19 21 7l-1.4-1.4L9 16.2z"
    />
  </svg>
);

const errorIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47
      2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52
      22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8
      8-8 8 3.58 8 8-3.58 8-8 8z"
    />
  </svg>
);

const closeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41
      10.59 12 5 17.59 6.41 19 12 13.41 17.59 19
      19 17.59 13.41 12z"
    />
  </svg>
);

const infoIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 
      10 10 10 10-4.48 10-10S17.52 2 12 2zm1 
      15h-2v-6h2v6zm0-8h-2V7h2v2z"
    />
  </svg>
);

type Props = {
  message: string;
  type: string;
  onClose: () => void;
};

export function SnackbarItem({ message, type, onClose }: Props) {
  const [style, setStyle] = useState<string>();
  const [symbol, setSymbol] = useState<any>();

  useEffect(() => {
    switch (type) {
      case 'success':
        setStyle(styles.success);
        setSymbol(doneIcon);
        break;

      case 'error':
        setStyle(styles.error);
        setSymbol(errorIcon);
        break;

      case 'info':
        setStyle(styles.info);
        setSymbol(infoIcon);
        break;
      default:
        setStyle('');
    }
  }, [type]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onClose();
    }, 5000);
    return () => clearTimeout(timeout);

    //eslint-disable-next-line
  }, []);

  return (
    <div className={clsx(style, styles.snackbar)}>
      <div className={styles.symbol}>{symbol}</div>
      <div className={styles.message}>{message}</div>
      <div onClick={onClose} className={styles.button}>
        {closeIcon}
      </div>
    </div>
  );
}

export default SnackbarItem;
