import { MeasureType } from "../__generated__/graphql";

export const MeasureLabel = {
  [MeasureType.Piece]: 'шт',
  [MeasureType.Kg]: 'кг',
  [MeasureType.Gram]: 'гр',
  [MeasureType.Kilometr]: 'км',
  [MeasureType.Meter]: 'м',
  [MeasureType.Millimeter]: 'мм',
  [MeasureType.Meter2]: `м²`,
  [MeasureType.Meter3]: 'м³',
  [MeasureType.Set]: 'комплект',
  [MeasureType.Ton]: 'тонна',
  [MeasureType.Package]: 'упаковка',
  [MeasureType.Section]: 'секция',
  [MeasureType.Kit]: 'набор'
};

export enum OrderItemStatusId {
  DRAFT,
  ORDERED,
  REJECTED,
  APPROVED,
  CHANGED
}