import React from 'react';
import Skeleton from '../../Skeleton/Skeleton';
import styles from './OrdersMobileSkeleton.module.scss';

export const OrdersMobileSkeleton = () => {
  return (
    <div className={styles.box}>
      {Array.from(Array(2)).map((_, idx) => (
        <div key={idx}>
          <Skeleton borderRadius={5} height={200} />
        </div>
      ))}
    </div>
  );
};

export default OrdersMobileSkeleton;
