import React from 'react';
import { Chip, ChipColorType } from '../../Chip/Chip';
import { OrderItemStatus } from '../../../__generated__/graphql';

export enum OrderItemStatusLabel {
  'APPROVED' = 'Подтверждено',
  'CHANGED' = 'Есть изменения',
  'REJECTED' = 'Отказано',
  'DRAFT' = '',
  'ORDERED' = 'Заказан',
}

export const OrderItemStatusChip = ({status} : {status: OrderItemStatus}) => {
  switch (status) {
    case OrderItemStatus.Approved:
      return <Chip color={ChipColorType.success} title={OrderItemStatusLabel[status]} />;
    case OrderItemStatus.Changed:
      return <Chip color={ChipColorType.warning} title={OrderItemStatusLabel[status]} />;
    case OrderItemStatus.Rejected:
      return <Chip color={ChipColorType.error} title={OrderItemStatusLabel[status]} />;
    // case OrderItemStatus.Draft:
    //   return <></>;
    default:
      return <></>;
  }
};
