import React, { ReactNode } from 'react';
import styles from './ItemCard.module.scss';

export const ItemCard = (props : {children : ReactNode}) => {
  const { children } = props;
  return (
    <div className={styles.container}>
      {children}
    </div>
  );
};

export default ItemCard;