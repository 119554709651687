import styles from './SuggestSelect.module.scss';

type Props = {
  direction?: 'column' | 'row';
}

export const FeedbackThanks = ({ direction = 'column' }: Props) => {

  const style = {
    flexDirection: direction,
    textAlign: direction === 'column' ? 'center' : 'start',
    gap: direction === 'row' ? '100px' : 0,
    alignItems: direction === 'column' ? 'center' : 'flex-start',
  } as React.CSSProperties;

  return (
    <div style={style} className={styles.afterSuggestion}>
      <div>
        <p>Благодарим за обратную связь!</p>
        <p>Благодаря вам, мы создаем удобную площадку для закупок, а вы вносите вклад в будущее строительного рынка!</p>
      </div>
      {direction === 'column' 
      ? <svg xmlns="http://www.w3.org/2000/svg" width="44" height="36" viewBox="0 0 44 36" fill="none">
        <path d="M32 0C27.8 0 24.1 2.1 22 5.4C19.9 2.1 16.2 0 12 0C5.4 0 0 5.4 0 12C0 23.9 22 36 22 36C22 36 44 24 44 12C44 5.4 38.6 0 32 0Z" fill="#F44336"/>
      </svg> 
      : <svg xmlns="http://www.w3.org/2000/svg" width="60" height="51" viewBox="0 0 60 51" fill="none">
        <path d="M43.3332 0.5625C37.7332 0.5625 32.7998 3.49375 29.9998 8.1C27.1998 3.49375 22.2665 0.5625 16.6665 0.5625C7.8665 0.5625 0.666504 8.1 0.666504 17.3125C0.666504 33.9229 29.9998 50.8125 29.9998 50.8125C29.9998 50.8125 59.3332 34.0625 59.3332 17.3125C59.3332 8.1 52.1332 0.5625 43.3332 0.5625Z" fill="#F44336"/>
      </svg>
      }
    </div>
  );
};