import React from 'react';
import styles from './Chip.module.scss';

export enum ChipColorType {
  default = '#e1e1e1',
  primary = '#b2e3f2',
  success = '#92e242',
  error = '#fc9e9e',
  warning = '#fbe597'
}

export type ChipProps = {
  title: string;
  color?: ChipColorType
}

export function Chip (props : ChipProps) {
  const { title, color = ChipColorType.default } = props;
  
  return (
    <div className={styles.chip} style={{background: color}}>{title}</div>
  );
}
