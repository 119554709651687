import { gql } from "../../../__generated__";

  export const ORDERS_QUERY = gql(`
    query Orders( $limit: Int!, $offset: Int!, $filter: OrdersListFilter) {
      orders(filter: $filter, pagination: {limit: $limit, offset: $offset}) {
        totalCount
        data {
          id
          created_at
          summ
          status
          num
        }
      }
    }
  `);

export const CANCEL_ORDER = gql(`
  mutation Mutation($orderId: String!) {
    cancelOrder(id: $orderId)
  }
`);

export const ORDER_QUERY = gql(`
  query Order($orderId: String!) {
    order(id: $orderId) {
      id
      status
      summ
      delivery_summ
      delivery_address
      num
      created_at
      items {
        productItem {
          id
          amount
          price
          supply_days
          title
          store {
            id
            city {
              id
              name
              region {
                name
                id
              }
            }
            title
          }
        }
        orderItem {
          id
          amount
          price
          status
          store_amount
          supply_days
          request_proposal_item {
            id
            price
            amount
            supply_days
          }
          position_num
        }
      }
      request {
        id
        num
      }
    }
  }
`);

export const ASK_HELP_ORDER = gql(`
  query HelpMailOrder($orderId: String!, $message: String!) {
    helpOrderMail(orderId: $orderId, message: $message)
  }
`);