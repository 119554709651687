import { useEffect, useRef, useState } from "react";
import styles from './HelpPopover.module.scss';

const helpIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M8.955 14.4C9.27 14.4 9.5364 14.2911 9.7542 14.0733C9.972 13.8555 10.0806 13.5894 10.08 13.275C10.08 12.96 9.9714 12.6936 9.7542 12.4758C9.537 12.258 9.2706 12.1494 8.955 12.15C8.64 12.15 8.3739 12.2589 8.1567 12.4767C7.9395 12.6945 7.8306 12.9606 7.83 13.275C7.83 13.59 7.9389 13.8564 8.1567 14.0742C8.3745 14.292 8.6406 14.4006 8.955 14.4ZM8.145 10.935H9.81C9.81 10.44 9.8664 10.05 9.9792 9.765C10.092 9.48 10.4106 9.09 10.935 8.595C11.325 8.205 11.6325 7.8336 11.8575 7.4808C12.0825 7.128 12.195 6.7044 12.195 6.21C12.195 5.37 11.8875 4.725 11.2725 4.275C10.6575 3.825 9.93 3.6 9.09 3.6C8.235 3.6 7.5411 3.825 7.0083 4.275C6.4755 4.725 6.1044 5.265 5.895 5.895L7.38 6.48C7.455 6.21 7.6239 5.9175 7.8867 5.6025C8.1495 5.2875 8.5506 5.13 9.09 5.13C9.57 5.13 9.93 5.2614 10.17 5.5242C10.41 5.787 10.53 6.0756 10.53 6.39C10.53 6.69 10.44 6.9714 10.26 7.2342C10.08 7.497 9.855 7.7406 9.585 7.965C8.925 8.55 8.52 8.9925 8.37 9.2925C8.22 9.5925 8.145 10.14 8.145 10.935ZM9 18C7.755 18 6.585 17.7636 5.49 17.2908C4.395 16.818 3.4425 16.1769 2.6325 15.3675C1.8225 14.5575 1.1814 13.605 0.7092 12.51C0.237 11.415 0.0006 10.245 0 9C0 7.755 0.2364 6.585 0.7092 5.49C1.182 4.395 1.8231 3.4425 2.6325 2.6325C3.4425 1.8225 4.395 1.1814 5.49 0.7092C6.585 0.237 7.755 0.0006 9 0C10.245 0 11.415 0.2364 12.51 0.7092C13.605 1.182 14.5575 1.8231 15.3675 2.6325C16.1775 3.4425 16.8189 4.395 17.2917 5.49C17.7645 6.585 18.0006 7.755 18 9C18 10.245 17.7636 11.415 17.2908 12.51C16.818 13.605 16.1769 14.5575 15.3675 15.3675C14.5575 16.1775 13.605 16.8189 12.51 17.2917C11.415 17.7645 10.245 18.0006 9 18ZM9 16.2C11.01 16.2 12.7125 15.5025 14.1075 14.1075C15.5025 12.7125 16.2 11.01 16.2 9C16.2 6.99 15.5025 5.2875 14.1075 3.8925C12.7125 2.4975 11.01 1.8 9 1.8C6.99 1.8 5.2875 2.4975 3.8925 3.8925C2.4975 5.2875 1.8 6.99 1.8 9C1.8 11.01 2.4975 12.7125 3.8925 14.1075C5.2875 15.5025 6.99 16.2 9 16.2Z" fill="#666666"/>
  </svg>
);

type Props = {
  content: string;
  ml?: number;
}

export const HelpPopover = (props : Props) => {
  const { content, ml } = props;

  const [showPopover, setShowPopover] = useState(false);
  const [showBottom, setShowBottom] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    setShowPopover(true);
    const popoverRect = popoverRef.current!.getBoundingClientRect();
    const rightOverflow = window.innerWidth - popoverRect.right;
    setShowBottom(rightOverflow <= 200);
  };

  const handleMouseLeave = () => {
    setShowPopover(false);
  };

  useEffect(() => {
    return () => {
      setShowPopover(false);
    };
  }, []);
  
  return (
    <span style={{marginLeft: ml}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={styles.popoverContainer} ref={popoverRef}>
      <span className={styles.popoverTrigger}>
        {helpIcon}
      </span>
      {showPopover && (
        <span className={showBottom ? styles.popoverContentBottom : styles.popoverContentRight}>
          {content}
        </span>
      )}
    </span>
  );
};