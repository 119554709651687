import React, { InputHTMLAttributes, useRef } from 'react';
import styles from './Checkbox.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const Checkbox = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div className={styles.inputContainer}>
      <input ref={inputRef} className={styles.checkbox} {...props} type="checkbox" id="checkbox" />
      {props.label && (
        <label onClick={() => inputRef.current?.click()} className={styles.label} htmlFor="checkbox">
          {props.label || ''}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
