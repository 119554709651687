import { FormikProps } from 'formik';
import styles from './SuggestSelect.module.scss';
import { TextField } from '../../inputs/TextField/TextField';
import { FormField } from '../../inputs/FormField/FormField';

type FormikOptions = {
  value: {value: string, extra?: string}[];
}

type Props<T> = {
  type: 'radio' | 'checkbox';
  withInput?: boolean;
  options: FormikOptions['value'];
  field: keyof T;
  formik: FormikProps<T>;
}

const SuggestSelect = <T, >({ type, withInput = false, options, formik, field }: Props<T>) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (type === 'checkbox') {
      const currentValue = formik.values[field] as unknown as {label: string, value: {value: string, extra?: string}[]};
      if (checked) {
        formik.setFieldValue(String(field), {...formik.values[field], value: [...currentValue.value, {value, extra: ''}]});
      } else {
        formik.setFieldValue(String(field), {...formik.values[field], value: currentValue.value.filter(v => v.value !== value)});
      }
    } else {
      formik.setFieldValue(String(field), {...formik.values[field], value: [{value, extra: ''}]});
    }
  };

  const handleChangeOther = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(String(field), {...formik.values[field], other: e.target.value});
  };

  const handleChangeExtra = (e: React.ChangeEvent<HTMLInputElement>, option: string) => {
    formik.setFieldValue(String(field), 
      {...formik.values[field], 
        value: (formik.values[field] as FormikOptions)['value'].map((v) => v.value === option 
          ? {...v, extra: e.target.value} 
          : v)
      }
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.options}>
        {options.map((option) => (
          <label
            className={styles.customCheckbox}
            key={option.value}
            itemType={type}
            itemProp={(formik.errors['options' as keyof typeof formik.errors] as any)?.[option.value]?.extra ? 'error' : 'default'}
          >
            <div className={styles.inputContainer}>
              <input
                type={type}
                id={option.value}
                name={String(field)}
                value={option.value}
                checked={(formik.values[field] as FormikOptions)['value'].some(v => v.value === option.value)}
                onChange={handleChange}
                className={styles.checkbox}
              />
              <span
                className={styles.checkboxText}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    ((e.target as HTMLSpanElement).previousElementSibling as HTMLInputElement).click();
                  }
                }}
              >
                {option.value}
              </span>
            </div>
            {(formik.values[field] as FormikOptions)['value'].some(v => v.value === option.value) && option.extra && 
              <FormField error={(formik.errors['options' as keyof typeof formik.errors] as any)?.[option.value]?.extra}>
                <TextField
                  disabled={!(formik.values[field] as FormikOptions)['value'].some(v => v.value === option.value)}
                  onChange={(e) => handleChangeExtra(e, option.value)}
                  placeholder={option.extra}
                  type="text"
                  className={styles.inputOther}
                  tabIndex={0}
                  clearBtnIndex={0}
                  value={(formik.values[field] as FormikOptions)['value'].find(v => v.value === option.value)?.extra || ''}
                />
              </FormField>
            }
          </label>
        ))}
      </div>
      {withInput && <TextField onChange={handleChangeOther} placeholder={'Другое'} type="text" className={styles.inputOther} />}
    </div>
  );
};

export default SuggestSelect;