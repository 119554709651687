import React from 'react';
import BillingCard from './components/BillingCard';

import styles from './CompanyBillingsList.module.scss';
import { CompanyQuery } from '../../__generated__/graphql';

type Props = {
  billings: CompanyQuery['companyBillings'];
  onEdit: () => void;
};

export const CompanyBillingsList = ({ billings, onEdit }: Props) => {
  return billings.length > 0 ? (
    <div>
      <div className={styles.container}>
        {billings.map((billing) => (
          <BillingCard key={billing.id} billing={billing} onEdit={onEdit} />
        ))}
      </div>
    </div>
  ) : (
    <div>Вы пока не добавили реквизиты</div>
  );
};

export default CompanyBillingsList;
