import { gql } from "../../__generated__";
import { client } from "./graphql";

export async function fetchRegions(name?: string) {
  const res = await client.query({
    query: gql(`
      query Regions($search: String) {
        regions(search: $search) {
          id
          name
        }
      }
    `),
    variables: {
      search: name
    },
  });
  return res.data.regions;
}

export async function fetchDistricts(name?: string) {
  const res = await client.query({
    query: gql(`
      query Districts($search: String) {
        districts(search: $search) {
          id
          name
        }
      }
    `),
    variables: {
      search: name
    },
  });
  return res.data.districts;
}