import { gql } from "../../../__generated__";
import { client } from "../graphql";

export const COMPANIES_QUERY = gql(`
  query Companies {
    companies {
      id
      inn
      name
      address
    }
  }
`);

export const COMPANY_AND_BILLINGS_QUERY = gql(`
  query Company($companyId: Int!) {
    company(id: $companyId) {
      id
      inn
      name
      address
    }
    companyBillings(id: $companyId) {
      id
      bik
      bank_name
      account
      is_active
    }
  }
`);

export const COMPANY_CREATE_MUTATION = gql(`
  mutation CompanyCreate($data: CompanyPayload!) {
    companyCreate(data: $data) {
      id
      address
      inn
      name
    }
  }
`);

export const BILLING_QUERY = gql(`
  query CompanyBilling($billingId: Int!) {
    companyBilling(id: $billingId) {
      id
      is_active
      bik
      bank_name
      account
    }
  }
`);

export async function fetchCompanies() {
  const res = await client.query({
    query: gql(`
      query Companies {
        companies {
          id
          inn
          name
          address
        }
      }
    `),
    fetchPolicy: 'no-cache'
  });
  return res.data.companies;
};

export const BILLING_CREATE_MUTATION = gql(`
  mutation CompanyBillingCreate($data: BillingPayload!, $companyId: Int!) {
    companyBillingCreate(data: $data, id: $companyId) {
      id
    }
  }
`);

export const BILLING_UPDATE_MUTATION = gql(`
  mutation CompanyBillingUpdate($data: BillingPayload!, $billingId: Int!) {
    companyBillingUpdate(data: $data, id: $billingId) {
      id
    }
  }
`);

export const LOOKUP_COMPANY_QUERY = gql(`
  query LookupCompanyByINN($inn: String!) {
    company: lookupCompanyByINN(inn: $inn) {
      name
      address
    }
  }
`);

export const LOOKUP_BANK_QUERY = gql(`
  query LookupBankByBIK($bik: String!) {
    bank: lookupBankByBIK(bik: $bik) {
      name
    }
  }
`);
