import { FormikProps } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { TextField, FormField } from '../..';

type Props<T> = {
  formik: FormikProps<T>;
  field: keyof T;
  nestedField?: string;
  type?: string;
  placeholder?: string;
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  label?: string;
  required?: boolean;
  disabled?: boolean;
};

export function FormikTextField<T>(props: Props<T>) {
  const { formik, field, nestedField, ...rest } = props;
  const [touched, setTouched] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const value = useMemo(() => {
    if (nestedField) {
      const keys = nestedField.split('.');

      const v = keys.reduce((res: any, el: any) => {
        if (!res) {
          return '';
        }
        return res[el];
      }, formik.values[field]);

      return v;
    }
  }, [nestedField, field, formik]);

  useEffect(() => {
    if (nestedField) {
      const keys = nestedField.split('.');

      if (formik.errors[field]) {
        const errors = keys.reduce((res: any, el: any) => {
          if (res === undefined || res === null) {
            return '';
          }
          return res[el];
        }, formik.errors[field]);

        setError(errors || '');
      } else setError('');

      if (formik.touched[field]) {
        const touched = keys.reduce((res: any, el: any) => {
          if (res === undefined || res === null) {
            return false;
          }
          return res[el];
        }, formik.touched[field]);
        setTouched(touched || false);
      } else setTouched(false);

    }
  }, [nestedField, field, formik]);

  return (
    <FormField
      label={rest.label}
      required={rest.required}
      error={
        nestedField
          ? touched && error
            ? error
            : ''
          : formik.touched[field] && formik.errors[field]
          ? String(formik.errors[field])
          : undefined
      }
    >
      <TextField
        name={nestedField ? String(field) + '.' + nestedField : String(field)}
        {...rest}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        clearfn={() => formik.setFieldValue(nestedField ? String(field) + '.' + nestedField : String(field), '')}
        value={nestedField ? value : String(formik.values[field])}
      />
    </FormField>
  );
}