import React, { useState } from 'react';
import Image from 'next/image';
import { Autocomplete } from '..';
import { fetchDistricts, fetchRegions } from '../../api/public/region';

import styles from './GeoSelector.module.scss';

type Props<T> = {
  items: T[];
  onRemoveItem: (idx: number) => void;
  onAddItem: (item: T | null) => void;
  error?: string;
  geo: 'region' | 'district';
};

export const GeoSelector = <T extends {id: number, name: string},>({ items, onRemoveItem, onAddItem, error, geo }: Props<T>) => {
  const [showSelector, setShowSelector] = useState<boolean>(false);

  const handleAddItem = (item: T | null) => {
    if (item) {
      onAddItem(item);
      setShowSelector(false);
    }
  };

  return (
    <div>
      <div className={styles.regionsContainer}>
        {items.map((r) => (
          <div key={r.id} className={styles.regionBox}>
            <span>{r.name}</span>
            <div className={styles.removeRegionBtn} onClick={() => onRemoveItem(r.id)} title="Удалить">
              <Image src="/svg/close.svg" width={12} height={12} alt="" />
            </div>
          </div>
        ))}
        {!showSelector ? (
          <div className={styles.addButtonBox}>
            <div
              className={styles.addButton}
              onClick={() => {
                setShowSelector(true);
              }}
            >
              <Image src="/svg/add.svg" width={30} height={30} alt="" />
            </div>
            <span>{geo === 'region' ? 'Добавить регион' : 'Добавить округ'}</span>
          </div>
        ) : (
          <Autocomplete
            fetch={geo === 'region' ? fetchRegions : fetchDistricts}
            name={geo === 'region' ? 'Регион' : 'Округ'}
            value={null}
            getLabel={(item) => item.name}
            onSelect={handleAddItem}
            placeholder={geo === 'region' ? 'Выберите регион' : 'Выберите округ'}
          />
        )}
      </div>
      {error && <p className={styles.errorText}>{error}</p>}
    </div>
  );
};

export default GeoSelector;
