import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import styles from './ErrorAlert.module.scss';

type Props = {
  error?: ApolloError | Error | string;
  mapMessage?: { [key: string]: string };
};

const defaultErrorsMap: {[k: string]: string} = {
  'Failed to fetch': 'Отсутствует соединение с сервером',
  'NetworkError when attempting to fetch resource.': 'Отсутствует соединение с сервером',
};

export const ErrorAlert: React.VFC<Props> = (props) => {
  const {
    error,
    mapMessage = {}
  } = props;
  const messages = {
    ...defaultErrorsMap,
    ...mapMessage,
  };

  if (!error) return null;
  return (
    <div className={styles.container}>
      {error instanceof ApolloError && error.graphQLErrors && error.graphQLErrors.length ? (
        error.graphQLErrors?.map(({ message, extensions }: GraphQLError, i: number) => (
          <p key={i}>
            {typeof extensions?.code === 'string' && messages[extensions?.code]
              ? messages[extensions?.code]
              : messages[message]
              ? messages[message] : error.message
            }
          </p>
        ))
      ) : (
        error &&
        error instanceof Error &&
        error.message && <p>{(messages[error.message]) || error.message}</p>
      )}
      {typeof error === 'string' && <p>{messages[error] || error}</p>}
    </div>
  );
};

export default ErrorAlert;
