import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { Select } from '../Select/Select';

type Props<T, F> = {
  formik: FormikProps<F>;
  field: keyof F;
  placeholder?: string;
  nestedField?: string;
  options: any[];
  getLabel: (v: T) => string;
  label?: string;
};

export function FormikSelect<T extends { id: number | string }, K>(props: Props<T, K>) {
  const { formik, field, placeholder, getLabel, label, options, nestedField } = props;

  const [value, setValue] = useState<any>(null);
  const [touched, setTouched] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (nestedField) {
      const keys = nestedField.split('.');

      const val = keys.reduce((res: any, el: any) => {
        if (!res) {
          return '';
        }
        return res[el];
      }, formik.values[field]);

      if (formik.errors[field]) {
        const errors = keys.reduce((res: any, el: any) => {
          if (res === undefined || res === null) {
            return null;
          }
          return res[el];
        }, formik.errors[field]);
        setError(errors || '');
      } else setError('');

      if (formik.touched[field]) {
        const touched = keys.reduce((res: any, el: any) => {
          if (res === undefined || res === null) {
            return null;
          }
          return res[el];
        }, formik.touched[field]);
        setTouched(touched || false);
      } else setTouched(false);

      setValue(val);
    }
  }, [nestedField, field, formik]);

  const handleSelect = (v: T | null) => {
    if (nestedField) {
      formik.setFieldValue(String(field) + '.' + nestedField, v);
      formik.setFieldTouched(String(field) + '.' + nestedField, true, false);
      return;
    }
    formik.setFieldValue(String(field), v);
    formik.setFieldTouched(String(field), true, false);
  };

  const errorMessage = () => {
    if (nestedField) {
      if (touched && error) {
        return error;
      } else return '';
    } else if (!nestedField) {
      if (formik.touched[field] && formik.errors[field]) {
        return String(formik.errors[field]);
      } else return '';
    }
  };

  return (
    <Select<T>
      label={label}
      name={nestedField ? String(field) + '.' + nestedField : String(field)}
      error={errorMessage()}
      placeholder={placeholder}
      getLabel={getLabel}
      options={options}
      value={nestedField ? value : (formik.values[field] as unknown as T)}
      onBlur={formik.handleBlur}
      onSelect={handleSelect}
    />
  );
}
