import { gql } from "../../__generated__";

export const PAGINATED_PRODUCTS_QUERY = gql(`
  query GetProductsPaginated(
    $limit: Int!
    $offset: Int!
    $category_id: Int!
  ) {
    products(
      pagination: {
        limit: $limit
        offset: $offset
      }
      category_id: $category_id
    ) {
      totalCount
      data {
        id
        title
        price
        amount
        measure
        supply_days
        store {
          city {
            name
          }
        }
        images {
          id
          sort_order
        }
      }
    }
  }
`);

export const SEARCH_PRODUCTS_QUERY = gql(`
  query GetProducts(
    $limit: Int!
    $offset: Int!
    $search: String!
  ) {
    products: productsSearch(
      pagination: {
        limit: $limit
        offset: $offset
      }
      search: $search
    ) {
      totalCount
      data {
        id
        title
        price
        amount
        measure
        top_category
        supply_days
        store {
          city {
            name
          }
        }
        images {
          id
          sort_order
        }
      }
    }
  }
`);


export const PRODUCT_DETAILS_QUERY = gql(`
  query ProductDetails($productId: String!) {
    productDetails(product_id: $productId) {
      title
      id
      vendor_code
      store {
        city {
          name
        }
      }
      measure
      amount
      supply_days
      description
      price
      top_category
      images {
        id
        sort_order
      }
    }
  }
`);