import React from 'react';
import styles from './Skeleton.module.scss';

type Props = {
  width?: number | string;
  height?: number | string;
  borderRadius?: number | string;
};

export const Skeleton = ({ width, height, borderRadius }: Props) => {
  return <div style={{ width, height, borderRadius }} className={styles.skeleton}></div>;
};

export default Skeleton;
