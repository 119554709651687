import axios from 'axios';

export function uploadFile(file: File) {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(`${process.env.NEXT_PUBLIC_API_URL}/file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}
