import Link from 'next/link';

export const FormAgreement = () => {
  return (
    <p style={{ fontSize: '90%', maxWidth: '530px', marginTop: '10px' }}>
      Отправляя форму, Вы принимаете и соглашаетесь с{' '}
      <Link href="/info/agreement">
        пользовательским соглашением
      </Link> и{' '}
      <Link href="/info/privacy-policy">
        правилами обработки персональных данных
      </Link>
      .
    </p>
  );
};

export default FormAgreement;
