
export const DEFAULT_CATEGORIES = [
  {
    id: '1',
    slug: 'otoplenie-i-vodosnabzhenie',
    title: 'Отопление и водоснабжение',
  },
  {
    id: '429',
    slug: 'santehnika',
    title: 'Сантехника',
  }, 
  {
    id: '598',
    slug: 'elektrosnabzhenie',
    title: 'Электроснабжение',
  }, 
  {
    id: '374',
    slug: 'nasosnoe-oborudovanie',
    title: 'Насосное оборудование',
  },
  {
    id: '401',
    slug: 'kanalizaciya',
    title: 'Канализация',
  }, 
  {
    id: '476',
    slug: 'ventilyaciya',
    title: 'Вентиляция',
  }, 
  {
    id: '557',
    slug: 'kondicionirovanie-i-holodosnabzhenie',
    title: 'Кондиционирование и холодоснабжение',
  }, 
  {
    id: '679',
    slug: 'ohranno-pozharnye-sistemy',
    title: 'Охранно-пожарные системы',
  }, 
  {
    id: '678',
    slug: 'vysokovoltnoe-elektrosnabzhenie',
    title: 'Высоковольтное электроснабжение',
  }, 
  {
    id: '683',
    slug: 'gazosnabzhenie',
    title: 'Газоснабжение',
  }, 
  {
    id: '681',
    slug: 'dispetcherizaciya-i-avtomatizaciya',
    title: 'Диспетчеризация и автоматизация',
  }, 
  {
    id: '682',
    slug: 'sistemy-kontrolya-i-upravleniya-dostupom',
    title: 'Системы контроля и управления доступом',
  }, 
  {
    id: '680',
    slug: 'strukturirovannye-kabelnye-seti-i-seti-svyazi',
    title: 'Структурированные кабельные сети и сети связи',
  }, 
  {
    id: '684',
    slug: 'instrumenty',
    title: 'Инструменты',
  },
  {
    id: 'aboba',
    slug: 'metizy',
    title: 'Метизы'
  }
].map((c) => ({
  ...c,
  parent_category: null,
  top_category: null,
}));
