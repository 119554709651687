import clsx from 'clsx';
import styles from './PageContainer.module.scss';
import { Breadcrums } from '../Breadcrums/Breadcrums';

type Props = {
  breadcrums?: Array<{
    text: string,
    url?: string,
  }>;
  children?: React.ReactNode;
  contentStyles?: React.CSSProperties;
}

function PageContainer({ breadcrums, children, contentStyles }: Props) {

  return (
    <main className={clsx(styles.container)}>
      {breadcrums?.length && <Breadcrums
        items={breadcrums}
      />}
      <div style={contentStyles} className={styles.content}>
        {children}
      </div>
    </main>
  );
};

export default PageContainer;