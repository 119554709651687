import { ButtonHTMLAttributes } from "react";
import styles from "./Button.module.scss";
import Link from "next/link";

export type ButtonProps = {
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  variant?: 'outlined' | 'filled' | 'secondary' | 'outlined-mini';
  icon?: React.ReactNode;
  type?: 'button' | 'link';
  href?: string;
  style?: ButtonHTMLAttributes<HTMLButtonElement>['style'];
  children: React.ReactNode;
  action?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
}


function Button(props: ButtonProps) {
  const { onClick, loading, disabled, variant = 'outlined', icon, action, children, type = 'button', style, href } = props;
  
  return type === 'button' ? (
    <button className={styles[variant]} type={action} style={style} onClick={onClick} disabled={loading || disabled}>
      {icon}
      {children}
    </button>
  ) : (
    <Link href={href || ''} className={styles[variant]} style={style}>
      {icon}
      {children}
    </Link>
  );
};

export { Button };