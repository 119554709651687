
const useConstants = (): any => {
  const domain = 'ensys.store';
  return {
    contacts: {
      phones: {
        main: {
          label: '8 (800) 600-5130 ',
          phone: '+78006005130',
        },
      },
      emails: {
        sales: `sales@${domain}`,
        support: `support@${domain}`,
        partner: `partner@${domain}`,
        ceo: `ceo@${domain}`,
      },
    },
  };
}

export {useConstants};
