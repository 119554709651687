import React from 'react';
import Skeleton from '../../Skeleton/Skeleton';
import styles from '../ProductsList.module.scss';

const ProductListSkeleton = () => {
  return (
    <div style={{ marginTop: 30 }}>
      <div className={styles.skeletonTable}>
        <div className={styles.skeletonHeader}>
          <Skeleton />
        </div>
        <div className={styles.skeletonHeader}>
          <Skeleton />
        </div>
        <div className={styles.skeletonHeader}>
          <Skeleton />
        </div>
        <div className={styles.skeletonHeader}>
          <Skeleton />
        </div>
        <div></div>
        {Array.from(new Array(5)).map((_, idx) => (
          <React.Fragment key={idx}>
            <div className={styles.skeletonItem}>
              <Skeleton />
            </div>
            <div className={styles.skeletonItem}>
              <Skeleton />
            </div>
            <div className={styles.skeletonItem}>
              <Skeleton />
            </div>
            <div className={styles.skeletonItem}>
              <Skeleton />
            </div>
            <div className={styles.skeletonItem}>
              <Skeleton />
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ProductListSkeleton;
