import { FormikProps } from 'formik';
import React, { useState } from 'react';
import { FormikTextField } from '../FormikTextField/FormikTextField';

type Props<T> = {
  field: keyof T;
  formik: FormikProps<T>;
  placeholder?: string;
  label?: string;
  required?: boolean;
};

export function FormikPhoneInput<T>({ formik, field, ...rest }: Props<T>) {
  const [finalValue, setFinalValue] = useState<string>('');
  const [currentValue, setCurrentValue] = useState<string>('');

  const changePhoneInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    let inputNumbersValue = getInputNumbers(input.value),
      formatedInputValue = '';
    const selectionStart = input.selectionStart;

    if (!inputNumbersValue) {
      input.value = '';
      return;
    }

    if (selectionStart === 1 && input.value.length > 1) {
      input.value = currentValue;
      return;
    }

    if (input.value.length >= 18 && selectionStart !== input.value.length) {
      input.value = finalValue || String(formik.values[field]);
      return;
    }

    if (['7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
      //Russian number

      if (inputNumbersValue[0] == '9') {
        inputNumbersValue = '7' + inputNumbersValue;
      }

      const firstSymbol = '+7';
      formatedInputValue = firstSymbol + ' ';

      if (inputNumbersValue.length > 1) {
        formatedInputValue += '(' + inputNumbersValue.substring(1, 4);
      }

      if (inputNumbersValue.length >= 5) {
        formatedInputValue += ') ' + inputNumbersValue.substring(4, 7);
      }

      if (inputNumbersValue.length >= 8) {
        formatedInputValue += '-' + inputNumbersValue.substring(7, 11);

        setFinalValue(formatedInputValue);
      }
    } else if (inputNumbersValue[0] === '+' && ['7', '8', '9'].indexOf(inputNumbersValue[1]) > -1) {
      formatedInputValue = '+';

      if (inputNumbersValue.length > 1) {
        formatedInputValue += inputNumbersValue[1];
      }

      if (inputNumbersValue.length > 2) {
        formatedInputValue += ' (' + inputNumbersValue.substring(2, 5);
      }

      if (inputNumbersValue.length >= 6) {
        formatedInputValue += ') ' + inputNumbersValue.substring(5, 8);
      }

      if (inputNumbersValue.length >= 9) {
        formatedInputValue += '-' + inputNumbersValue.substring(8, 12);

        setFinalValue(formatedInputValue);
      }
    } else if (
      (inputNumbersValue[0] === '+' && ['7', '8', '9'].indexOf(inputNumbersValue[1]) === -1) ||
      ['7', '8', '9'].indexOf(inputNumbersValue[0]) === -1
    ) {
      //Not Russian number
      if (inputNumbersValue[0] === '+') {
        formatedInputValue = inputNumbersValue.substring(0, 17);

        setFinalValue(formatedInputValue);
      } else {
        formatedInputValue = '+' + inputNumbersValue.substring(0, 17);

        setFinalValue(formatedInputValue);
      }
    }
    setCurrentValue(formatedInputValue);

    if (input.value.length != selectionStart) {
      const { data } = e.nativeEvent as InputEvent;
      if (data && /\D/g.test(data)) {
        input.value = formatedInputValue;
      }
      return;
    }

    input.value = formatedInputValue;
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasted = e.clipboardData;
    const input = e.target as HTMLInputElement;
    const inputNumbersValue = getInputNumbers(input.value);

    if (pasted) {
      const pastedText = pasted.getData('Text');
      if (/\D/g.test(pastedText)) {
        input.value = inputNumbersValue;
      }
    }
  };

  const getInputNumbers = (value: string) => {
    if (value && value[0] === '+') {
      const newVal = '+' + value.replace(/\D/g, '');
      return newVal;
    }
    return value.replace(/\D/g, '');
  };

  return (
    <FormikTextField
      {...rest}
      formik={formik}
      field={field}
      onPaste={handlePaste}
      onInput={changePhoneInput}
      type="tel"
    />
  );
}

export default FormikPhoneInput;
