import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';
import { formatDate } from '../../features/date';
import { Button, CabinetContentLayout } from '../../components';
import styles from './RequestsTable.module.scss';
import { RequestStatusChip } from '../RequestDetails/components/RequestStatusChip';
import { QueryQuery } from '../../__generated__/graphql';
import Download from '../../public/images/newSVG/downloadWhite.svg';
import Plus from '../../public/images/newSVG/plus.svg';

export enum RequestStatus {
  'OPEN' = 'Открыта',
  'CANCELED' = 'Отменена',
  'ORDERED' = 'Заказана',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  'SENDED' = 'Открыта',
  'CLOSED' = 'Закрыта',
  'DRAFT' = 'Черновик'
}

const RequestsTable = ({ requests }: { requests: QueryQuery['requests']['data'] }) => {
  const router = useRouter();

  return (
    <>
      <CabinetContentLayout header={
        <div className={styles.headerContainer}>
          <h3>Заявки и спецификации</h3>
          <div>
            <Button type='link' href='/upload-specification' icon={<Download />} variant='filled'>Загрузить спецификацию</Button>
            <Button type='link' href='/cabinet/requests/new' icon={<Plus />}>Оформить заявку</Button>
          </div>
        </div>
      }>
        {requests.length > 0 ? (
          <div className={styles.table}>
            <div className={styles.ordersContainer}>
              {requests.map((r) => (
                <div
                  className={clsx(
                    styles.row,
                    styles.order,
                  )}
                  key={r.id}
                >
                  <div className={styles.num}>
                    <span>Заявка № {r.num}{r.title ? `, ${r.title}` : ''}, {formatDate(new Date(r.created_at))}</span>
                  </div>
                  <div className={styles.status}>
                    <RequestStatusChip status={r.status} />
                  </div>
                  <div className={styles.orderBtn}>
                    <Button
                      onClick={() => {
                        router.push(`/cabinet/requests/${r.id}`);
                      }}
                      variant='outlined-mini'
                    >
                      Открыть
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>Вы еще ничего не заказывали</div>
        )}
      </CabinetContentLayout>
    </>
  );
};

export default RequestsTable;
