import ReactPagination from 'react-js-pagination';
import ArrowMini from '../../public/images/newSVG/arrowMini.svg';


type Props = {
  activePage: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
  onChange: (p: number) => void;
}

export function Pagination(props: Props) {
  return (
    <ReactPagination
      {...props}
      hideFirstLastPages
      innerClass="bl-pagination"
      nextPageText={<ArrowMini style={{ transform: 'rotate(90deg)' }} />}
      prevPageText={<ArrowMini style={{ transform: 'rotate(-90deg)' }} />}
    />
  );
}
