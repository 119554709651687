import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { PAGINATED_PRODUCTS_QUERY } from '../../api/public/products';
import { Button, Pagination } from '../../components';
import { ProductsListTable } from './components/ProductsListTable';
import styles from './ProductsList.module.scss';
import ProductListSkeleton from './components/ProductListSkeleton';
import { Category, GetProductsQuery } from '../../__generated__/graphql';
import { ContactManager } from '../ContactManager/ContactManager';

type Props = {
  categoryId?: number,
  search?: string,
  props?: any,
  parentCategory?: Category,
}

const PAGE_SIZE = 20;

export const ProductsList = ({ categoryId, parentCategory }: Props) => {

  const [page, setPage] = useState(1);
  const [isContactManagerModalOpen, setIsContactManagerModalOpen] = useState(false);

  const [refetch, { loading, error, data, }] = useLazyQuery(PAGINATED_PRODUCTS_QUERY, {
    variables: {
      limit: PAGE_SIZE,
      offset: (page - 1) * PAGE_SIZE,
      category_id: Number(categoryId),
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    refetch({
      variables: {
        limit: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
        category_id: Number(categoryId),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const products = data ? data.products.data : [];

  if (loading) {
    return (
      <ProductListSkeleton />
    );
  }
  if (error) {
    return (
      <p>
        Ошибка получения данных.
      </p>
    );
  }
  if (products.length === 0) {
    return (
      <div className={styles.noProducts}>
        <p>Товаров не найдено.</p>
        <p>Свяжитесь с менеджером, уточните критерии для подбора и мы отправим вам лучшие предложения от поставщиков.</p>
        <Button onClick={() => setIsContactManagerModalOpen(true)} variant='outlined' type='button'>
          Связаться с менеджером
        </Button>
        <ContactManager isModalOpen={isContactManagerModalOpen} setIsModalOpen={() => setIsContactManagerModalOpen(false)} />
      </div>
    );
  }

  return (
    <div>
      <div className={styles.tableContainer}>
        <ProductsListTable parentCategoryId={parentCategory?.id} products={products as GetProductsQuery['products']['data']} />
      </div>
      {data!.products.totalCount > PAGE_SIZE ? (
        <Pagination
          activePage={page}
          itemsCountPerPage={PAGE_SIZE}
          totalItemsCount={data!.products.totalCount}
          onChange={setPage}
        />
      ) : null}
    </div>
  );
};
