import { ChangeEvent } from 'react';
import styles from './TextField.module.scss';

type Props = {
  name: string;
  value: string;
  onChange: (e: ChangeEvent<any>) => void;
  rows?: number;
  id?: string;
}

export function TextAreaField(props: Props) {
  const {
    id,
    name,
    value,
    onChange,
    rows
  } = props;
  return (
    <textarea
      id={id}
      name={name}
      onChange={onChange}
      className={styles.inputArea}
      value={value}
      rows={rows}
      style={props.value ? {background: 'white', border: '1px solid #224FF0'} : {}}
    >
    </textarea>
  );
}