import React, { useState } from 'react';

import styles from './Select.module.scss';
import { FormField } from '../FormField/FormField';
import { TextField } from '../TextField/TextField';

export type SelectorProps<T> = {
  value: T | null;
  options: any[];
  getLabel: (v: T) => string;
  onSelect: (v: T | null) => void;
  error?: string;
  label?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export function Select<T extends { id: number | string }>(props: SelectorProps<T>) {
  const { id, name, value, placeholder, onBlur, getLabel, onSelect, options, error, label } = props;

  const [showList, setShowList] = useState<boolean>(false);

  const handleBlur = (e: React.FocusEvent<any>) => {
    if (onBlur) {
      onBlur(e);
    }
    setShowList(false);
  };

  const handleSelect = (v: T) => {
    onSelect(v);
  };

  const handleFocus = () => {
    setShowList(true);
  };

  return (
    <FormField error={error} label={label}>
      <div className={styles.container}>
        <div className={styles.inputBox}>
          <TextField
            id={id}
            name={name}
            value={value ? getLabel(value) : ''}
            autoComplete={'off'}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={placeholder}
            readOnly={true}
            clearfn={() => onSelect(null)}
          />
        </div>
        {showList ? (
          <div className={styles.listContainer}>
            <ul>
              {options?.map((c) => (
                <li key={c.id} onClick={() => handleSelect(c)} onMouseDown={() => handleSelect(c)}>
                  {getLabel(c)}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </FormField>
  );
}
