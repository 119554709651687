import { InputHTMLAttributes } from 'react';
import styles from './TextField.module.scss';
import Close from '../../../public/images/newSVG/close.svg';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  clearfn?: () => void;
  clearBtnIndex?: number;
}

function TextField(props: Props) {

  return (
    <div className={styles.container}>
      <input
        {...props}
        style={props.value ? {background: 'white', border: '1px solid #224FF0'} : {}}
        className={styles.input}
      />
      {(props.value && props.clearfn) &&
        <button 
          className={styles.closeBtn}
          type='button' 
          tabIndex={props.clearBtnIndex}
          // @ts-ignore
          onClick={() => (props.onChange && props.onChange({ target: {value: ''} })) || (props?.clearfn && props.clearfn())}
        >
          <Close />
        </button>
      }
    </div>
  );
}

export { TextField };