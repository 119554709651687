export function formatPrice(p: number) {
  // return p.toFixed(2) + ' ₽'; // + ' руб.';
  return Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(p) + ' ₽';
}

export function formatAmount(a: number) {
  if (a === -1) {
    return 'В наличии';
  }
  return a.toString();
}

export function findBotByUserAgent (str: string) {
  const bots = [
    'rambler','googlebot','aport','yahoo','msnbot','turtle','mail.ru','omsktele',
    'yetibot','picsearch','sape.bot','sape_context','gigabot','snapbot','alexa.com',
    'megadownload.net','askpeter.info','igde.ru','ask.com','qwartabot','yanga.co.uk',
    'scoutjet','similarpages','oozbot','shrinktheweb.com','aboutusbot','followsite.com',
    'dataparksearch','google-sitemaps','appEngine-google','feedfetcher-google',
    'liveinternet.ru','xml-sitemaps.com','agama','metadatalabs.com','h1.hrn.ru',
    'googlealert.com','seo-rus.com','yaDirectBot','yandeG','yandex',
    'yandexSomething','Copyscape.com','AdsBot-Google','domaintools.com',
    'Nigma.ru','bing.com','dotnetdotcom', 'bot'
  ];

  for (const bot of bots) {
    if (str.toLowerCase().includes(bot)) {
      return true;
    }
  }

  return false;
}

export const formatPhone = (phone: string) => {
  const formatedPhone = phone.replace(/\D/g, '');
  return `+7 (${formatedPhone.substr(1, 3)}) ${formatedPhone.substr(4, 3)}-${formatedPhone.substr(7, 4)}`;
};
