import { useRouter } from "next/router";

const PARAM_NAME = 'imid';

export const useReferalLink = (): [number | null, () => void] => {
  const router = useRouter();

  let managerId = router.query[PARAM_NAME] as string || null;

  if (typeof window !== "undefined") {
    if (managerId) {
      window.localStorage.setItem(PARAM_NAME, managerId);
      window.history.replaceState(null, '', window.location.href.split('?')[0]);
    } else {
      managerId = window.localStorage.getItem(PARAM_NAME);
    }
  }

  const clear = () => localStorage.removeItem(PARAM_NAME);

  return [managerId ? parseInt(managerId, 10) : null, clear];

};
