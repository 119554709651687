import React from 'react';
import Skeleton from '../../Skeleton/Skeleton';

const ItemSkeleton = () => {
  return (
    <>
      {Array.from(new Array(3)).map((_, idx) => (
        <div key={idx} style={{ marginBottom: '15px' }}>
          <Skeleton width={'100%'} height={50} borderRadius={5} />
        </div>
      ))}
    </>
  );
};

export default ItemSkeleton;
