import clsx from 'clsx';
import { useRouter } from 'next/router';
import { Button } from '..';
import { formatPrice } from '../../features/string';

import styles from './OrdersListTable.module.scss';
import { CabinetContentLayout } from '../../components';
import OrderStatusChip from '../OrderStatus/OrderStatusСhip';
import { OrdersQuery } from '../../__generated__/graphql';
import { formatDate } from '../../features/date';

export const OrdersListTable = ({ orders }: { orders: OrdersQuery['orders']['data'] }) => {
  const router = useRouter();

  return (
    <>
      {orders.length > 0 ? (
        <CabinetContentLayout header='Заказы'>
          <div>
            <div className={styles.ordersContainer}>
              {orders.map((o) => (
                <div className={clsx(styles.row, styles.order)} key={o.num}>
                  <div className={styles.num}>
                    <span>Заказ № {o.num}, {formatDate(new Date(o.created_at))}</span>
                  </div>
                  <div className={styles.price}>{formatPrice(o.summ)}</div>
                  <div className={styles.status}>
                    <OrderStatusChip status={o.status} />
                  </div>
                  <div className={styles.orderBtn}>
                    <Button
                      onClick={() => {
                        router.push(`/cabinet/orders/${o.id}`);
                      }}
                      variant='outlined-mini'
                    >
                      Открыть
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </CabinetContentLayout>
      ) : (
        <div>Вы еще ничего не заказывали</div>
      )}
    </>
  );
};

export default OrdersListTable;
