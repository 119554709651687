import React from 'react';
import { Chip, ChipColorType } from '../Chip/Chip';
import { OrderStatus } from '../../__generated__/graphql';

type Props = {
  status: OrderStatus;
}

export enum OrderStatusLabel {
  'DRAFT' = '',
  'NEW' = 'Согласовывается',
  'CONFIRMED' = 'Подтвержден',
  'PAYED' = 'Оплачен',
  'DELIVERY' = 'Доставка',
  'COMPLETED' = 'Завершен',
  'CANCELED' = 'Отменен'
}

const statusToColor: { [key in OrderStatus]: ChipColorType } = {
  [OrderStatus.New]: ChipColorType.warning,
  [OrderStatus.Canceled]: ChipColorType.error,
  [OrderStatus.Completed]: ChipColorType.success,
  [OrderStatus.Delivery]: ChipColorType.success,
  [OrderStatus.Payed]: ChipColorType.success,
  [OrderStatus.Draft]: ChipColorType.default,
  [OrderStatus.Confirmed]: ChipColorType.primary
};

export const OrderStatusChip = (props : Props) => {
  const { status } = props;

  return (
    <Chip title={OrderStatusLabel[status]} color={statusToColor[status]} />
  );
};

export default OrderStatusChip;
