import React from 'react';
import Image from 'next/image';
import styles from './RoundAddButton.module.scss';

type Props = {
  onClick: () => void;
  children?: React.ReactNode;
};

export const RoundAddButton = ({ onClick, children }: Props) => {
  return (
    <button className={styles.button}>
      <div className={styles.addButtonContainer}>
        <div className={styles.addButton} onClick={onClick}>
          <Image src="/svg/add.svg" width={30} height={30} alt="" />
        </div>
        {children && (typeof children === 'string' ? <span>{children}</span> : children)}
      </div>
    </button>
  );
};

export default RoundAddButton;
