import { gql } from "../../__generated__";

export const PLACE_ORDER = gql(`
  mutation PlaceOrder(
    $summ: Float
    $items: [OrderItemInput!]!
    $address: String!
    $companyId: Int!
  ) {
    placeOrder(
      summ: $summ
      items: $items
      address: $address
      companyId: $companyId
    ) {
      id
    }
  }
`);

export const UPDATE_ORDER = gql(`
  mutation ChangeOrderItem($status: Float!, $orderItemId: String!) {
    changeOrderItem(status: $status, orderItemId: $orderItemId) {
      id
    }
  }
`);