
type Props = {
  amount: number;
  supplyDays: number | null | undefined;
  show?: 'supplyDays' | 'amount';
}

const showAmount = (amount: number) => {
  if (amount < 0) {
    return <div style={{ whiteSpace: 'nowrap' }}>В наличии</div>;
  }
  if (amount === 0) {
    return <div style={{ whiteSpace: 'nowrap' }}>Нет в наличии</div>;
  }
  return <div style={{ whiteSpace: 'nowrap' }}>На складе: {amount}</div>;
};

const showSupplyDays = (supplyDays: number | null | undefined) => {
  if (supplyDays && supplyDays > 0) {
    return (
      <div 
        title="Срок поставки до склада поставщика" 
        style={{ whiteSpace: 'nowrap', textDecoration:'underline dotted 1px', textUnderlineOffset: '2px' }}
      >
        Под заказ: {supplyDays} дн
      </div>
    );
  }
  return null;
};

export const InStockView = (props : Props) => {
  const { supplyDays, amount, show } = props;
  
  if (show === 'supplyDays') {
    return showSupplyDays(supplyDays);
  }
  if (show === 'amount') {
    return showAmount(amount);
  }
  
  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      {showAmount(amount)}
      {showSupplyDays(supplyDays)}
    </div>
  );
};