import Link from 'next/link';
import { DEFAULT_CATEGORIES } from './categories';
import styles from './TopCategories.module.scss';
import { useRouter } from 'next/router';
import clsx from 'clsx';


type Props = {
  categories?: typeof DEFAULT_CATEGORIES;
}

export const TopCategories = ({ categories = DEFAULT_CATEGORIES }: Props) => {
  const router = useRouter();
  const isIndexPage = router.pathname === '/';

  return (
    <ul className={clsx(styles.catalog, !isIndexPage && styles.catalogNotIndex)}>
      {categories.map((category, i) => (
        <li key={category.id}>
          <Link href={`/catalog/${category.slug}`}>
              <span
                className={styles.catalogItemIcon}
                style={{ backgroundImage: category.top_category ? `` : `url(/images/newSVG/categories/category${i + 1}.svg)` }}
              />
              <p>{category.title}</p>
          </Link>
        </li>
      ))}
    </ul>
  );
};