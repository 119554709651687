import React from 'react';
import SnackbarItem from './components/SnackbarItem';
import styles from './Snackbar.module.scss';

type Props<T> = {
  items?: T[];
  onClose: (idx: number) => void;
};

//TODO implement correct snackbar removal

export function Snackbar<T extends { type: string; message: string }>({ items, onClose }: Props<T>) {
  if (!items) return null;
  return (
    <div className={styles.container}>
      {items.map((i, idx) => (
        <SnackbarItem key={idx} onClose={() => onClose(idx)} type={i.type} message={i.message} />
      ))}
    </div>
  );
}

export default Snackbar;
