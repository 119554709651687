import { RequestStatus } from "../../../__generated__/graphql";
import { Chip, ChipColorType } from "../../Chip/Chip";
import { RequestStatus as RequestStatusLabel } from '../../RequestsList/RequestsTable';

type Props = {
  status: RequestStatus;
}

const statusToColor: { [key in RequestStatus]: ChipColorType } = {
  [RequestStatus.Open]: ChipColorType.primary,
  [RequestStatus.Canceled]: ChipColorType.error,
  [RequestStatus.Sended]: ChipColorType.primary,
  [RequestStatus.Ordered]: ChipColorType.success,
  [RequestStatus.Closed]: ChipColorType.default,
  [RequestStatus.Draft]: ChipColorType.default,
};

export const RequestStatusChip = (props : Props) => {
  const { status } = props;

  return (
    <Chip title={RequestStatusLabel[status]} color={statusToColor[status]} />
  );
};
