import React from 'react';
import Skeleton from '../../Skeleton/Skeleton';
import styles from './ProductDetails.module.scss';

export const ProductDetailsSkeleton = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <h4>
            <Skeleton width={"150px"} height={"20px"}/>
          </h4>
          <Skeleton width={"70%"} height={"20px"}/>
        </div>
        <div className={styles.code}>
          <h4>
            <Skeleton width={"90px"} height={"20px"}/>
          </h4>
          <Skeleton width={"150px"} height={"20px"}/>
        </div>
        <div className={styles.description}>
          <h4>
            <Skeleton width={"150px"} height={"20px"}/>
          </h4>
          <Skeleton width={"80%"} height={"20px"}/>
        </div>
        <div>
          <h4>
          <Skeleton width={"90%"} height={"20px"}/>
          </h4>
          <Skeleton width={"100%"} height={"20px"}/>
        </div>
        <div>
          <h4>
          <Skeleton width={"90%"} height={"20px"}/>
          </h4>
          <Skeleton width={"100%"} height={"20px"} />
        </div>
        <div>
          <h4>
          <Skeleton width={"90%"} height={"20px"}/>
          </h4>
          <Skeleton width={"100%"} height={"20px"} />
        </div>
        <div>
          <h4>
            <Skeleton width={"90%"} height={"20px"}/>
          </h4>
          <Skeleton width={"100%"} height={"20px"} />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Skeleton width={"150px"} height={"40px"} />
      </div>
    </>
  );
};

export default ProductDetailsSkeleton;