import { useLazyQuery, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { BILLING_CREATE_MUTATION, BILLING_UPDATE_MUTATION, LOOKUP_BANK_QUERY } from '../../../../api/public/cabinet/companies';
import { Button, Checkbox, ErrorAlert, FormField, TextField } from '../../../../components';
import { CompanyQuery } from '../../../../__generated__/graphql';

import styles from './BillingEditForm.module.scss';
import { FormikTextField } from '../../../../components/inputs/FormikTextField/FormikTextField';

type Props = {
  billing?: CompanyQuery['companyBillings'][0];
  onEdit: () => void;
};

type Values = {
  bik: string;
  account: string;
  bank_name: string;
  is_active: boolean;
};

const REQUIRED = 'Обязательное поле';

const validationSchema = Yup.object().shape({
  bik: Yup.string()
    .matches(/^[\d+]{9}$/, 'Некорректный БИК').required(REQUIRED),
  account: Yup.string()
    .matches(/^[\d+]{20}$/, 'Некорректный номер счета').required(REQUIRED),
  bank_name: Yup.string().required(REQUIRED),
  is_active: Yup.boolean()
});

const BillingEditForm = ({ billing, onEdit }: Props) => {
  const { query } = useRouter();
  const [checkError, setCheckError] = useState('');

  const [createBilling, {error: createError, loading: createLoading}] = useMutation(BILLING_CREATE_MUTATION, {
    onCompleted: () => onEdit(),
    onError: (err) => console.error(err)
  });

  const [checkBank, { loading: checkLoading }] = useLazyQuery(LOOKUP_BANK_QUERY, {
    onCompleted: (data) => {
      if (data.bank) {
        const { name } = data.bank;
        setCheckError('');
        formik.setFieldValue('bank_name', name);
      }
    },
    onError: (err) => {
      if (err.message === 'NOT_FOUND') {
        setCheckError('Банк с данным БИК не найден');
        formik.setFieldValue('bank_name', '');
      }
      console.error(err);
    }
  });

  const [updateBilling, {error: updateError, loading: updateLoading}] = useMutation(BILLING_UPDATE_MUTATION, {
    onCompleted: () => onEdit(),
    onError: (err) => console.error(err)
  });

  const formik = useFormik<Values>({
    initialValues: {
      bik: billing?.bik || '',
      bank_name: billing?.bank_name || '',
      account: billing?.account || '',
      is_active: billing? billing.is_active : true,
    },
    validationSchema,
    onSubmit: (values) => {
      if (billing) {
        updateBilling({
          variables: {
            billingId: Number(billing.id),
            data: {
              ...values
            }
          }
        });
      } else {
        createBilling({
          variables: {
            companyId: Number(query.id),
            data: {
              ...values
            }
          }
        });
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      if (formik.values.bik) {
        checkBank({
          variables: {
            bik: formik.values.bik
          }
        });
      }
    };
  
    const timer = setTimeout(fetchData, 300);
    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.bik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.container}>
        <ErrorAlert error={updateError || createError} />
        <div className={styles.billingContainer}>
          <div className={styles.bik}>
            <FormikTextField formik={formik} field="bik" label="БИК банка" required disabled={!!billing} />
          </div>
          <div className={styles.account}>
            <FormikTextField formik={formik} field="account" label="Номер счета" required  disabled={!!billing} />
          </div>
          <div className={styles.bankName}>
          <FormField
            label="Наименование банка"
            error={checkError}
          >
            <TextField
              name={"Наименование банка"}
              value={formik.values.bank_name}
              disabled
            />
          </FormField>
          </div>
          <div className={styles.isActiveCheckBox}>
            <Checkbox
              checked={formik.values.is_active}
              onChange={() => {
                setTimeout(() => formik.setFieldValue('is_active', !formik.values.is_active));
              }}
              label="Активен"
            />
          </div>
        </div>
        <Button variant='filled' loading={createLoading || updateLoading || checkLoading} onClick={formik.handleSubmit}>
          Сохранить
        </Button>
      </div>
    </form>
  );
};

export default BillingEditForm;