import Skeleton from '../../Skeleton/Skeleton';
import styles from './OrdersSkeleton.module.scss';

const OrdersSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.item}>
          <Skeleton height={30} width={75} />
        </div>
        <div className={styles.item}>
          <Skeleton height={30} width={75} />
        </div>
        <div className={styles.item}>
          <Skeleton height={30} width={75} />
        </div>
        <div className={styles.item}>
          <Skeleton height={30} width={75} />
        </div>
      </div>
      <div className={styles.ordersContainer}>
        {Array.from(Array(4)).map((_, idx) => (
          <div className={styles.orderRow} key={idx}>
            <Skeleton borderRadius={5} height={45} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrdersSkeleton;
