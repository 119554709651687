import styles from './CabinetRequests.module.scss';
import { RequestStatusChip } from '../../RequestDetails/components/RequestStatusChip';
import { QueryQuery } from '../../../__generated__/graphql';
import { Button } from '../../Button/Button';
import { formatDateMonthShort } from '../../../features/date';


type Props = {
  requests: QueryQuery['requests']['data'];
};

const CabinetRequests = ({ requests }: Props) => {
  return (
    <div className={styles.requestsContainer}>
      {requests.map((request) => (
        <div className={styles.requestCard} key={request.id}>
          <div className={styles.requestNum}>№ {request.num} от {formatDateMonthShort(new Date(request.created_at))}</div>
          <div className={styles.requestStatus}>
            <RequestStatusChip status={request.status} />
          </div>
          <div className={styles.requestBtn}>
            <Button
              type='link'
              href={`/cabinet/requests/${request.id}`}
              variant='outlined-mini'
            >
              Открыть
            </Button>
          </div>
        </div>
      ))}
      <Button variant='outlined-mini' href='/cabinet/requests' type='link'>
        Смотреть все
      </Button>
    </div>
  );
};

export default CabinetRequests;
