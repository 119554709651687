import styles from './Footer.module.scss';
import LogoMini from '../../public/images/newSVG/logoMini.svg';
import Pattern from '../../public/images/newSVG/pattern.svg';
import Pattern2 from '../../public/images/newSVG/pattern2.svg';
import Link from 'next/link';
import { Button } from '../Button/Button';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <ul>
        <li>
          <Link href="/">
            <LogoMini />
          </Link>
        </li>
        <li>
          <p className={styles.footerTitle}>Покупателям</p>
          <ul className={styles.miniList}>
            <li>
              <Link href="/delivery">Доставка</Link>
            </li>
            <li>
              <Link href='/payment'>Способы оплаты</Link>
            </li>
            <li>
              <Link href='/return'>Условия возврата</Link>
            </li>
          </ul>
        </li>
        <li>
          <p className={styles.footerTitle}>Партнёрам</p>
          <ul className={styles.miniList}>
            <li>
              <Link href="https://partner.ensys.store/auth/join">Стать поставщиком</Link>
            </li>
          </ul>
        </li>
        <li>
          <p className={styles.footerTitle}>Компания</p>
          <ul className={styles.miniList}>
            <li>
              <Link href='/about'>О компании</Link>
            </li>
            <li>
              <Link href='/invest'>Инвесторам</Link>
            </li>
            <li>
              <Link href='/vacancies'>Вакансии</Link>
            </li>
          </ul>
        </li>
        <li>
          <p className={styles.footerTitle}>Контакты</p>
          <Button style={{ background: 'inherit', color: 'white' }} type='link' href='/contacts' variant='outlined-mini'>Связаться с нами</Button>
          <ul className={styles.contactList}>
            <li>
              <Link href='tel:+88006005130'>8 800 600-51-30</Link>
            </li>
            <li>
              <Link href='mailto:sales@ensys.store'>sales@ensys.store</Link>
            </li>
          </ul>
          <ul className={styles.miniList}>
            <li>
              <p style={{marginBottom: '10px'}} className={styles.footerTitle}>Техническая поддержка</p>
              <ul style={{gap: '5px'}} className={styles.miniList}>
                <li>
                  <Link href='mailto:sales@ensys.store'>sales@ensys.store</Link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className={styles.rightPattern}>
          <Pattern />
        </li>
      </ul>
      <div className={styles.bottomPattern}>
        <Pattern2 />
      </div>
      <p className={styles.bottomInfo}>
        © 2022-2024 Инженерный маркетплейс «Все инженерные системы» <br />
        <span style={{ fontSize: '12px', lineHeight: '16px' }}>
          Продолжая работу с сайтом, вы даёте согласие на использование сайтом cookies и <Link href="/info/privacy-policy">обработку персональных данных</Link> в целях функционирования сайта, <br />
          проведения ретаргетинга, статистических исследований, и улучшения сервиса.
        </span>
      </p>
    </footer>
  );
};