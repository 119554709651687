import React, { useState, useEffect } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { fetchCities, fetchInitialCity } from '../../api/public/city';
import { selectCity, setCity } from '../../store/mainSlice';
import { useDispatch, useSelector } from '../../store/hooks';
import { ErrorAlert, Modal, TextField } from '../../components';
import { getToken } from '../../store/loginSlice';
import { UPDATE_CITY_MUTATION } from '../../api/public/cabinet/profile';
import { useDebouncedState } from '../../hooks';
import { findBotByUserAgent } from '../../features/string';
import Place from '../../public/images/newSVG/place.svg';

import styles from './CitySelect.module.scss';
import { City } from '../../__generated__/graphql';

export const CitySelectNew = () => {
  const city = useSelector(selectCity);
  const token = useSelector(getToken);
  const dispatch = useDispatch();

  const [showSelect, setShowSelect] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [cities, setCities] = useState<City[]>([]);
  const [error, setError] = useState<ApolloError | undefined>();
  const [selectedCity, setSelectedCity] = useState<City>();

  const query = useDebouncedState(search, 500);

  const [updateCity, { error: cityUpdateError }] = useMutation(UPDATE_CITY_MUTATION, {
    onError: (err) => setError(err),
  });

  useEffect(() => {
    if (city) {
      setSelectedCity(city);
    }
  }, [city]);
  

  useEffect(() => {
    if (showSelect) {
      fetchCities(query)
        .then((resp) => {
        setCities(resp);
        setError(undefined);
      })
      .catch((err) => {
        setError(err);
      });
    }
  }, [query, showSelect]);

  useEffect(() => {
    const isBot = findBotByUserAgent(window.navigator.userAgent);

    if (!city) {
      fetchCities()
        .then((resp) => {
          setCities(resp);
          setError(undefined);
          if (!isBot) {
            fetchInitialCity()
              .then((city) => {
                dispatch(setCity(city || resp[0]));
              })
              .catch(() => dispatch(setCity(resp[0])));
          } else {
            dispatch(setCity(resp[0]));
          }
        })
        .catch((err) => {
          setError(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setShowSelect(true);
  };

  const changeCity = (city: City) => {
    if (token) {
      updateCity({
        variables: {
          cityId: city.id,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
    }
    setSearch('');
    dispatch(setCity(city));
    setShowSelect(false);
  };

  return (
    <>
      <button className={styles.container} onClick={handleClick}>
        <Place />
        <span className={styles.linkText}>{selectedCity ? selectedCity.name : ''}</span>
      </button>
      <Modal
        show={showSelect}
        onClose={() => setShowSelect(false)}
        title="Ваш город"
        containerClassName={styles.modal}
      >
        <TextField
          name="text"
          placeholder='Город'
          value={search}
          onChange={(e: React.ChangeEvent<any>) => {
            setSearch(e.target.value);
          }}
        />
        <div className={styles.selectCityList}>
          {cities.slice(0, 9).map((c) => (
            <div key={c.id} className={styles.item}>
              <button onClick={() => changeCity(c)}>{`${c.name}`}</button>
              {`${c.region.name}`}
            </div>
          ))}
        </div>
        <ErrorAlert
          error={error || cityUpdateError}
          mapMessage={{
            'Failed to fetch': 'Отсутсвует соединение с сервером',
          }}
        />
      </Modal>
    </>
  );
};

export default CitySelectNew;
