import React, { ReactNode } from 'react';
import styles from './Card.module.scss';

export const Card = (props : {children : ReactNode}) => {
  const { children } = props;
  return (
    <div className={styles.container}>
      {children}
    </div>
  );
};

export default Card;